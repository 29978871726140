@import '../variables.scss';

.ButtonSet {
  display: flex;
  flex-wrap: wrap;
  gap: var(--button-margin);

  &-button {
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    border: var(--border-width) solid var(--secondary-gray);
    cursor: pointer;
    font-size: var(--text-size-small);

    &.dark {
      border-radius: var(--border-radius-large);
      background-color: var(--secondary-dark);
      border: none;
    }
  }

  .selected {
    border: var(--border-width) solid var(--selected-color);
    color: var(--selected-color);

    &.dark {
      color: var(--ui-background);
      background-color: var(--selected-color);
      border: none;
    }
  }
}
