@import '../variables.scss';

.MenuSelector {
  display: flex;
  flex-direction: column;
  background-color: var(--ui-background);
  height: fit-content;
  margin-right: var(--standard-space);
  border-radius: var(--border-radius-small);

  &-button {
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--secondary-gray);

    &:first-of-type {
      border-top-left-radius: var(--border-radius-small);
      border-top-right-radius: var(--border-radius-small);
    }

    &:last-of-type {
      border-bottom-left-radius: var(--border-radius-small);
      border-bottom-right-radius: var(--border-radius-small);
      border-bottom: none;
    }

    &.active {
      background-color: var(--selected-color);
      .MenuSelector-icon-container {
        filter: var(--filter-ui-background);
      }
    }

    &:hover {
      background-color: var(--selected-color);
      .MenuSelector-icon-container {
        filter: var(--filter-ui-background);
      }
    }

    &.disabled {
      .MenuSelector-icon-container {
        filter: var(--filter-svg-medium-gray);
      }
      background-color: var(--secondary-gray);
      pointer-events: none;
    }
  }

  &-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
