@import '../../variables.scss';

.Histogram {
  --percent-width: 36px;

  &-screenshot-padding {
    padding: var(--standard-space);

    & .Histogram-open-button {
      display: none;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: var(--standard-space);
    gap: 0.5rem;

    &-label {
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    .menu-primary-label {
      margin-bottom: unset;
    }
  }

  &-section {
    width: 100%;
    display: flex;
    font-size: 0.85rem;
    gap: 0.1rem;
    align-items: center;
  }

  &-label {
    font-size: var(--text-size-small);
    font-weight: 500;
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-graph {
    display: flex;
    width: calc(70% - var(--percent-width));
    height: var(--graph-height);
    background-color: var(--graph-background);
  }

  &-bar {
    background-color: var(--selected-color);
  }

  &-percent {
    display: flex;
    align-items: center;
    width: var(--percent-width);
    margin-left: var(--button-margin);
  }

  &-open-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &-download-button {
    cursor: pointer;
    font-size: var(--text-size-small);
    font-weight: normal;
    color: var(--medium-gray);
    margin-left: var(--standard-space);
  }

  .chart {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.Tooltip {
  display: flex;
  flex-direction: column;
  gap: var(--dropdown-spacing);
  padding: var(--standard-space);
  border-radius: var(--border-radius-small);
  background-color: var(--standard-text-color);
  color: var(--ui-background);

  &-title {
    font-weight: bold;
  }

  &-underline {
    border-bottom: 1px solid var(--ui-background);
  }

  &-row {
    display: flex;
    gap: var(--standard-space);
  }

  &-swatch {
    width: var(--standard-space);
    height: var(--standard-space);
  }
}
