@import '../variables.scss';

.Dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: var(--text-size-small);

  &-border-helper-left {
    border-left: var(--border-width) solid var(--secondary-gray);
    height: 100%;
    position: absolute;
    &.dark {
      border: none;
    }
  }

  &-border-helper-right {
    right: 0;
    border-right: var(--border-width) solid var(--secondary-gray);
    height: 100%;
    position: absolute;
    &.dark {
      border: none;
    }
  }

  &.position-bottom {
    justify-content: flex-start;
    .Dropdown-list {
      margin-top: calc(var(--dropdown-height) + (var(--border-width) * 2));
      border-top: none;
    }

    .Dropdown-border-helper-left {
      margin-top: calc(var(--dropdown-height) / 2);
    }
    .Dropdown-border-helper-right {
      margin-top: calc(var(--dropdown-height) / 2);
    }
  }

  &.position-top {
    justify-content: flex-end;
    .Dropdown-list {
      margin-bottom: calc(var(--dropdown-height) + (var(--border-width) * 2));
      border-bottom: none;
    }

    .Dropdown-border-helper-left {
      margin-bottom: calc(var(--dropdown-height) / 2);
    }
    .Dropdown-border-helper-right {
      margin-bottom: calc(var(--dropdown-height) / 2);
    }
  }

  &-current {
    border: var(--border-width) solid var(--secondary-gray);
    height: var(--dropdown-height);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    justify-content: space-between;
    border-radius: var(--border-radius-small);

    &-label {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      display: flex;
      align-items: center;
    }

    &.dark {
      border: none;
      background-color: var(--secondary-dark);
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &-caret {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.dark {
      filter: var(--filter-ui-background);
    }
  }

  &-list {
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--ui-background);
    border: var(--border-width) solid var(--secondary-gray);
    width: calc(100% - (var(--border-width) * 2));
    z-index: 1;
    max-height: var(--max-dropdown-list-height);
    overflow-y: auto;
    &.dark {
      border: none;
      background-color: var(--secondary-dark);
    }
  }

  &-option {
    display: flex;
    align-items: center;
    padding-top: var(--dropdown-spacing);
    padding-bottom: var(--dropdown-spacing);
    cursor: pointer;
    padding-left: var(--button-padding);
    padding-right: var(--button-padding);
    width: calc(100% - (var(--button-padding) * 2));

    &:hover {
      background-color: var(--secondary-gray);
      &.dark {
        background-color: var(--standard-text-color);
      }
    }
  }
}
