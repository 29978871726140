@import '../../variables.scss';

.MapMenu {
  display: flex;
  flex-direction: column;
  width: 100%;

  .sections {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
