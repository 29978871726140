@import '../../variables.scss';

.FiltersMenu {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-header-container {
    display: flex;
    align-items: center;
  }

  &-apply-button-container {
    display: flex;
    align-items: center;
    margin-left: var(--standard-space);
  }

  &-apply-button {
    background-color: var(--selected-color);
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    color: var(--ui-background);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--text-size-small);
  }

  &-reset-button {
    background-color: none;
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--text-size-small);

    &.disabled {
      pointer-events: none;
      color: var(--medium-gray);
    }
  }
}
