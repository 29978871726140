.tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  margin-left: 5px;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  color: white;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -20px;
  font-size: 12px;
  text-wrap: nowrap;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}
