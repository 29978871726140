@import '../variables.scss';

.customModal {
  border-radius: 10px;
  max-width: 520px;
}

.PasswordContainer {
  width: 400px;
  display: flex;
  gap: 2px;
  flex-direction: column;

  &-header {
    font-size: 16px;
    font-weight: 500;
    color: var(--standard-text-color);
    margin-bottom: 10px;
  }

  &-error {
    color: #ea3e3e;
    font-size: 14px;
    cursor: pointer;
  }

  &-input {
    border: 1px solid var(--medium-gray);
    /* height: 20px; */
    border-radius: 5px;
    width: 300px;
    padding-left: 5px;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(58, 55, 72, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Inter;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: transparent;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d4d4d4;
    border-radius: 4px;
    transition: all 0.2s;
    outline: none;
  }

  &-button {
    color: #fff;
    background-color: #246f8c;
    outline: none;
    border: none;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    margin-top: 10px;
  }
}
