@import '../variables.scss';

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  float: right;
  font-size: 24px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-heading {
  padding: 5px;
  font-weight: 500;
  padding-top: 10px;
}

.modal-header {
  padding: 2px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
  margin: 15px 0;

  input {
    padding: 5px;
  }

  .emptySpaces {
    border-color: var(--warning-color);
  }
}

.modal-body.formValidation {
  .modal-input {
    border-color: var(--warning-color);
  }
}

.modal-footer {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .create-button {
    background-color: var(--selected-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 80px;
    padding: 5px;
    cursor: pointer;
  }

  .cancel-button {
    background: red;
    color: white;
    border: none;
    border-radius: 10px;
    width: 80px;
    cursor: pointer;
  }
}
