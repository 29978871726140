.CustomLayersInput {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .active-layers {
    background: var(--gray-light-background);
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
