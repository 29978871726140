@import '../variables.scss';

.Toggle {
  display: flex;
  width: 100%;
  max-width: var(--menu-min-width);
  align-items: center;
  justify-content: space-between;

  &.disabled {
    pointer-events: none;
  }

  &.primary {
    gap: var(--standard-space);
  }

  &.secondary {
    border: var(--border-width) solid var(--secondary-gray);
    border-radius: var(--button-border-radius);
  }

  &-option {
    cursor: pointer;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--dropdown-height);
    font-size: var(--text-size-small);

    &.primary {
      background-color: var(--unselected-gray);
      border-radius: var(--dropdown-height);
    }

    &.secondary {
      background-color: var(--ui-background);

      &:first-of-type {
        border-top-left-radius: var(--button-border-radius);
        border-bottom-left-radius: var(--button-border-radius);
      }

      &:last-of-type {
        border-top-right-radius: var(--button-border-radius);
        border-bottom-right-radius: var(--button-border-radius);
      }
    }
  }

  .unselected {
    &.disabled {
      background-color: var(--unselected-gray);
      color: var(--secondary-gray);
    }
  }

  .selected {
    &.primary {
      background-color: var(--selected-color);
      color: var(--ui-background);
    }

    &.secondary {
      background-color: var(--selected-low-opacity-color);
      color: var(--blue-dark);
      border: var(--border-width) solid var(--blue-dark);
    }
  }
}
