.NumberInput {
  display: flex;
  align-items: center;

  input {
    border: var(--border-width) solid var(--secondary-gray);
    height: var(--dropdown-height);
    border-radius: var(--border-radius-small);
    padding-left: var(--button-padding);
    background-color: var(--ui-background);
  }
}
