@import '../variables.scss';

.TripsCount {
  &-horizontal-line {
    background-color: var(--unselected-gray);
    width: 100%;
  }

  &-header {
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;

    &-button {
      background-color: unset;
      outline: none;
      border: none;
      border-bottom: 1px solid var(--selected-color);
      color: var(--selected-color);
      padding: unset;
      cursor: pointer;
    }
  }

  &-slider-container {
    position: relative;
    margin-top: 40px;
    width: 98%;
    margin-left: 4px;

    .rc-slider-track,
    .rc-slider-tracks {
      background-color: var(--selected-color);
    }

    .rc-slider-handle {
      border: 2px solid var(--selected-color);
    }

    &-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-block {
        width: 80px;
        margin-top: 15px;
      }

      &-input {
        width: 100%;
        margin-bottom: 2px;
        border-radius: 4px;
        border: 1px solid var(--secondary-gray);
        padding: 5px;
        outline: none;
      }
    }
  }

  &-slider {
    width: unset;
    margin-top: 30px;
    margin-left: 10px;
  }
}

#slider-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -25px;
  left: -5px;
  right: -5px;
  color: var(--selected-color);
}
