@import '../../variables.scss';

.SelectionsMenu {
  display: flex;
  flex-direction: column;
  overflow: auto;

  &-section {
    margin-bottom: var(--standard-space);
  }

  &-dropdown-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-highlighted-number {
    color: var(--medium-gray);
    margin-left: var(--standard-space);
  }
}
