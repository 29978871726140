@import '../../variables.scss';

.ChartsDisplayMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--menu-max-width);

  &-section {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--standard-space);
  }

  &-dropdown-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-button {
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    color: var(--ui-background);
    font-size: var(--text-size-small);
    min-width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--selected-color);

    &:hover {
      background-color: var(--selected-color-hover);
    }

    &.disabled {
      background-color: var(--medium-gray);
      color: var(--standard-text-color);
      pointer-events: none;
    }
  }

  &-loader-container {
    width: calc(100% - (var(--standard-space) * 2));
    height: calc(100% - (var(--standard-space) * 2));
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
