@import '../variables.scss';

.PaletteSwitcher {
  background-color: var(--ui-background);
  width: 100%;

  &-dropdown-section {
    margin-bottom: var(--standard-space);
  }

  &-label {
    display: flex;
    align-items: center;
    width: 100%;

    &-name {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-control-container {
    display: flex;
    justify-content: space-between;
    gap: var(--standard-space);
  }

  &-palette-container {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--standard-space);
  }

  &-reverse-button {
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
