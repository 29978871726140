@import '../../variables.scss';

.DownloadMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--menu-max-width);

  .menu-secondary-label {
    margin-top: 0.5rem;
    margin-bottom: unset;
  }

  &-spatial-file-set {
    margin-bottom: 0.5rem;
  }

  &-section {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--standard-space);
  }

  &-odFlows-block {
    padding-bottom: 20px;
  }

  &-row-container {
    display: flex;
    width: 100%;
    gap: var(--standard-space);
    justify-content: space-between;
    align-items: center;
  }

  &-input {
    outline: none;
    padding: 3px;
    color: white;
  }

  &-input::placeholder {
    color: lightgray;
  }

  &-odMenuHeader {
    display: flex;
  }

  &-dropdown-container {
    display: flex;
    flex-direction: column;
    gap: var(--standard-space);
  }

  &-buttonset-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--standard-space);
    margin-bottom: var(--standard-space);
  }

  &-button {
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    color: var(--ui-background);
    font-size: var(--text-size-small);
    min-width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--selected-color);

    &:hover {
      background-color: var(--selected-color-hover);
    }

    &.disabled {
      background-color: var(--medium-gray);
      color: var(--standard-text-color);
      pointer-events: none;
    }
  }

  &-loader-container {
    width: calc(100% - (var(--standard-space) * 2));
    height: calc(100% - (var(--standard-space) * 2));
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
