@import '../variables.scss';

.TabSet {
  display: flex;
  width: 100%;
  border-bottom: var(--border-width) solid var(--secondary-gray);
  gap: var(--button-margin);

  &-container {
    display: flex;
    flex-shrink: 0;
  }

  &-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: nowrap;
    &.disabled {
      pointer-events: none;
    }
  }

  &-label {
    font-size: var(--text-size-small);
    margin-bottom: var(--button-margin);
    flex-wrap: nowrap;

    &.selected {
      color: var(--selected-color);
      font-weight: 500;
    }

    &.disabled {
      color: var(--medium-gray);
    }
  }

  &-underline {
    width: 100%;
    &.selected {
      margin-bottom: calc(var(--border-width) * -1);
      border-bottom: calc(var(--border-width) * 2) solid var(--selected-color);
    }
  }

  &-filler-underline {
    width: var(--standard-space);
    &-grow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
    }
  }
}
