@import '../variables.scss';

.VisualizationsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  &-header-label {
    display: flex;
    align-items: center;
  }

  &-icon-container {
    margin-left: var(--standard-space);
    display: flex;
    align-items: center;
    gap: var(--standard-space);
  }

  &-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-header-container {
    display: flex;
    flex-direction: column;
    gap: var(--standard-space);

    &-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menu-header-label {
        margin-bottom: unset;
      }
    }
  }

  &-loader-container {
    height: 100%;
    width: 100%;
  }

  &-histogram-container {
    display: flex;
    flex-direction: column;
    gap: var(--standard-space);
  }

  &-collapse-button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-collapse-button-label {
    color: var(--selected-color);
    font-size: var(--text-size-small);
    font-weight: bold;
  }

  &-collapse-button {
    padding: var(--button-padding);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-legend {
    display: flex;
    flex-direction: column;

    &-segmentation-header {
    }

    &-label-container {
      display: flex;
      flex-wrap: nowrap;
      gap: calc(var(--button-margin) / 2);
      align-items: center;
      cursor: pointer;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      gap: var(--button-margin);
    }

    &-swatch {
      height: calc(var(--button-padding) * 2);
      width: calc(var(--button-padding) * 2);
    }

    &-label {
    }
  }

  .chart-segmentation-button {
    height: 18px;
    width: 18px;

    &-container {
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .overflow-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    overflow-y: auto;
  }
}
