@import '../variables.scss';

.GroupContainer {
  display: flex;

  & .GroupSet {
    display: flex;
    background-color: var(--medium-gray);
    border-radius: var(--button-border-radius);
    overflow: hidden;

    & .GroupSetSelected {
      color: var(--blue-dark);
      background-color: var(--ui-background);
    }

    & p {
      margin: 0;
      padding: 0.25rem 1rem;
      color: var(--standard-text-color);
      border-right: 1px solid var(--standard-text-color);
      cursor: pointer;
    }
  }
}
