@import '../variables.scss';

.react-dropdown-tree-select {
  & .dropdown {
    width: 95%;
    margin-bottom: 0.5rem;

    & .dropdown-trigger {
      width: 100%;
      border-radius: var(--button-border-radius);
      border-color: var(--selected-color);

      & .tag-item {
        width: 90%;
      }

      & .search {
        width: 100%;
      }
    }

    & .dropdown-content {
      width: 100%;
      border-radius: var(--button-border-radius);
      border: var(--border-width) solid var(--selected-color);
    }
  }
}
