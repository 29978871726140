@import '../variables.scss';

.Loader {
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  &-spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid var(--loader-color-opacity);
    border-radius: 50%;
    border-top-color: var(--loader-color);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
