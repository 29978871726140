.Map {
  height: 100%;
  width: 100%;

  &-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
}

// These must reflect all possible map ids
#origin {
  height: 100%;
  width: 100%;
  position: relative;
}

#destination {
  height: 100%;
  width: 100%;
  position: relative;
}

.mapboxgl-ctrl-bottom-left {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;

  .mapboxgl-ctrl-scale {
    border-width: 1px;
  }
}
