@import '../variables.scss';

.RadioButtonSet {
  display: flex;
  flex-wrap: wrap;
  gap: var(--button-margin);

  &-container {
    display: flex;
    align-items: center;
    gap: var(--standard-space);
  }

  &-button-border {
    height: calc(var(--button-padding) * 2);
    width: calc(var(--button-padding) * 2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: var(--border-width) solid var(--medium-gray);

    .selected {
      border: var(--border-width) solid var(--selected-color);
    }
  }

  &-button-center {
    height: calc((var(--button-padding) * 2) - 4px);
    width: calc((var(--button-padding) * 2) - 4px);
    border-radius: 50%;
    background-color: var(--selected-color);
  }
}
