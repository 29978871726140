@import '../variables.scss';

.Header {
  height: 3rem;
  width: calc(100% - (var(--standard-space) * 2));
  background-color: var(--standard-text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding-left: var(--standard-space);
  padding-right: var(--standard-space);

  &-button-container {
    display: flex;
    align-items: center;
  }

  &-left-side-container {
    display: flex;
    align-items: center;
  }

  &-names-container {
    display: flex;
    align-items: baseline;
  }

  &-project-name {
    margin-left: var(--standard-space);
    color: var(--ui-background);
  }

  &-view-name {
    margin-left: var(--standard-space);
    color: var(--ui-background);
    font-size: var(--text-size-small);
    font-weight: bold;
  }

  &-icon-button {
    margin-left: var(--standard-space);
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--selected-color);
    }
  }

  &-button {
    margin-left: var(--standard-space);
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    color: white;
    background-color: var(--selected-color);
    font-size: var(--text-size-small);
    min-width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--selected-color-hover);
    }
  }

  &-hamburger-menu {
    margin-left: var(--standard-space);
    padding: var(--button-padding);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
