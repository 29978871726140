@import '../variables.scss';

.Sidebar {
  width: 30%;
  max-width: var(--menu-max-width);
  min-width: var(--menu-min-width);
  display: flex;
  flex-direction: column;
  gap: var(--standard-space);
  padding: var(--standard-space);
  flex-grow: 1;

  &.hidden {
    display: none;
  }

  &-stats-container {
    display: flex;
    flex-direction: column;
    background-color: var(--ui-background);
    border-radius: var(--border-radius-small);
    padding: var(--standard-space);
  }

  &-visualizations-container {
    display: flex;
    overflow-y: auto;
    background-color: var(--ui-background);
    border-radius: var(--border-radius-small);
    padding: var(--standard-space);
  }

  &-overflow-container {
    width: 100%;
    display: flex;
  }

  &-total-trips {
    display: flex;
    margin-right: var(--standard-space);
    margin-left: var(--standard-space);
    flex-direction: column;
    margin-bottom: var(--dropdown-spacing);

    &-label {
      font-weight: 600;
      font-size: 1rem;
    }

    &-value {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  &-charts-container {
    flex-grow: 1;
  }
}
