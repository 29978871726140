.CustomLayerEntry {
  align-items: center;
  border: 1px solid var(--secondary-gray);
  border-radius: 100px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;

  .description {
    align-items: center;
    display: flex;
    color: var(--standard-text-color);
    flex-direction: row;
    gap: 0.5rem;
  }

  .layer-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 1rem;
  }

  .actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .action {
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  .settings-modal-container {
    position: relative;
    width: 0;

    .MenuContainer {
      border: 1px solid var(--secondary-gray);
    }
  }

  .settings-modal {
    position: absolute;
    top: -0.5rem;
    left: 1rem;

    .MenuContainer {
      min-width: 7rem;
    }
  }

  .CustomLayerSettings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .remove-button {
      appearance: none;
      background: none;
      border: none;
      color: var(--orange);
      cursor: pointer;
      padding: 0;
    }
  }

  .CustomLayerStyleSettings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .CustomLayerStyleField {
      label {
        color: var(--standard-text-color);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        font-size: 0.9em;
      }
    }
  }
}
