@import '../../variables.scss';

.reportContainer {
  & .pdf-btn {
    margin-top: 1rem;
    margin-bottom: 0.4rem;

    & a {
      background-color: var(--blue-dark);
      color: var(--ui-background);
      padding: 0.25rem 1rem;
      border-radius: var(--button-border-radius);
      text-decoration: none;
    }

    & .Loader {
      width: 20px;
      height: 20px;
    }

    & .Loader-spinner {
      width: 14px;
      height: 14px;
    }
  }

  .noteBox {
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;
    font-size: 12px;

    & label {
      margin-bottom: 0.25rem;
    }

    & textarea {
      border-radius: 4px;
      padding: 0.5rem 1rem;
      font-family: 'Inter';
      height: 5rem;
      resize: none;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
