@import '../variables.scss';

.FiltersContainer {
  display: flex;
  flex-direction: column;
  width: var(--menu-min-width);
  max-height: 360px;
  overflow-y: auto;
  position: relative;

  &-section {
    margin-bottom: var(--standard-space);
  }

  &-label {
    margin-bottom: 0.25rem;
    font-weight: bold;
  }

  &-loader-container {
    min-height: 360px;
    width: 100%;
  }
}
