.ColorInput {
  &-input {
    background: none;
    border: none;
    cursor: pointer;
    height: 2em;
    width: 2em;
    padding: 0;
    margin: 0;
    appearance: none;
  }
}
