@import '../variables.scss';

.MenuContainer {
  display: flex;
  padding: var(--standard-space);
  background-color: var(--ui-background);
  border-radius: var(--border-radius-small);
  min-width: var(--menu-min-width);
  position: relative;
  z-index: 2;

  &.dark {
    background-color: var(--standard-text-color);
    color: var(--ui-background);
  }

  &.downloadMenu {
    height: 600px;
    overflow-y: auto;
  }

  &-close-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
  }
}
